var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignup = {
    templateContainer: $(),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }
      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
        var $emailSuccessMessage = $('.js-email-signup-block-success', $emailContainerNode);
        var $emailErrorMessage = $('.js-email-signup-block-error', $emailContainerNode);
        var $emailAlreadySignedUpMessage = $('.js-email-signup-block-already-signed-up', $emailContainerNode);
        var $gdprCheck = $('.js-gdpr-check', $emailContainerNode);
        var $signupButton = $('.js-email-signup-block__submit', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);

        $gdprCheck.on('change', function () {
          $signupButton.toggleClass('button--disabled', !this.checked).prop('disabled', !this.checked);
        });

        $emailForm.once('js-email-signup').on('submit', function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccessMessage.add($emailErrorMessage).add($emailAlreadySignedUpMessage).addClass('hidden');
          $emailInput.removeClass('error');

          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

              if (alreadySignedUp) {
                $(document).trigger('email_signup:success_but_signed_up');
                $emailAlreadySignedUpMessage.removeClass('hidden');
              } else {
                $(document).trigger('email_signup:success_and_new');
                $emailSuccessMessage.removeClass('hidden');
                $emailInput.val('');
              }
            },
            onFailure: function (jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();
              var errors = '';

              $emailErrorMessage.removeClass('hidden');

              for (var i = 0; i < errorObjectsArray.length; i++) {
                var myErr = errorObjectsArray[i];

                if (myErr && myErr.key) {
                  if (
                    myErr.key === 'required.pc_email_address.email_signup' ||
                    myErr.key === 'invalid.pc_email_address.email_signup'
                  ) {
                    errors += myErr.text + '<br/>';
                    $emailInput.addClass('error').focus();
                  }
                }
              }
              if (errors) {
                $emailErrorMessage.html(errors);
              }
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context) {
      site.emailSignup.templateContainer = $('.js-email-signup-block-v1', context);
      site.emailSignup.initEmailSignup();
    }
  };
})(jQuery);
